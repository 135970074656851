// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const cycleOrder = ["JK2gXaFf1"];

const variantClassNames = {JK2gXaFf1: "framer-v-ovfppr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, U8XFNn5jd: link ?? props.U8XFNn5jd, uMkB273gN: title ?? props.uMkB273gN ?? "Kapcsolat"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, U8XFNn5jd, uMkB273gN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JK2gXaFf1", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-uBK81", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={U8XFNn5jd} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-ovfppr", className)} framer-6wlzdi`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"JK2gXaFf1"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Termékek</motion.p></React.Fragment>} className={"framer-zbkoay"} layoutDependency={layoutDependency} layoutId={"BqtOVT7j9"} style={{"--framer-paragraph-spacing": "0px"}} text={uMkB273gN} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-uBK81 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uBK81 .framer-6wlzdi { display: block; }", ".framer-uBK81 .framer-ovfppr { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 75px; }", ".framer-uBK81 .framer-zbkoay { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 75
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"U8XFNn5jd":"link","uMkB273gN":"title"}
 */
const FramerfDLwkx3VG: React.ComponentType<Props> = withCSS(Component, css, "framer-uBK81") as typeof Component;
export default FramerfDLwkx3VG;

FramerfDLwkx3VG.displayName = "Kapcsolat";

FramerfDLwkx3VG.defaultProps = {height: 32, width: 75};

addPropertyControls(FramerfDLwkx3VG, {U8XFNn5jd: {title: "Link", type: ControlType.Link}, uMkB273gN: {defaultValue: "Kapcsolat", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerfDLwkx3VG, [...sharedStyle.fonts])